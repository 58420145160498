<div class="banner">
  <img
    src="../../../assets/images/banner-kajah.jpg"
    width="100%"
    height="400px"
    alt=""
  />
</div>

<div class="container-top">
  <div class="navigation">
    <a (click)="goBack()">Go Back </a>
  </div>
</div>

<section class="section">
  <div class="section-container" data-aos="fade-up" data-aos-duration="1500">
    <!-- <hr class="line"> -->
    <div class="flex-container">
      <div class="introduction">
        <h2>KAJAH INDUSTRIES</h2>
        <p>
          The Rajah Company, one of the business enterprises under the Rajah
          Group, is a leading manufacturer in India with over 42,000 employees.
          Our products meet great demands in the states of Kerala, Tamil Nadu,
          Karnataka, Andhra Pradesh, and Maharashtra.
        </p>
      </div>

      <div>
        <img
          src="../../../assets/images/about3.jpg"
          width="400px"
          height="300px"
          alt=""
          id="img"
        />
      </div>
    </div>
  </div>
  <!-- intro ends here -->

  <div class="section-container" data-aos="fade-up" data-aos-duration="1500">
    <h2>Our Core Value</h2>

    <div class="core-text">
      <div>
        <p>
          We firmly stand on the ethical values of the business and constantly
          strive to blend the modern lifestyle with Mother Nature. Our zeal lies
          in the pursuit of business opportunities in fields that address man’s
          everyday needs and hence we are sure to spell success in business
          ventures we undertake in the contemporary global market. The Company’s
          Board of Directors is a blend of young minds and insightful
          experience, a formulation very essential to sustain consolidation. We
          work in the spirit of togetherness to deliver products and services
          par excellence. We are truthful in our commitment and uphold the law
          in our projects. We are recipients of the ‘Best Tax Payer’ Awards in
          the states of Kerala and TamilNadu.
        </p>
      </div>
    </div>
  </div>

  <div class="section-container" data-aos="fade-up" data-aos-duration="1500">
    <h2>Our Products</h2>

    <div class="flex-container">
      <div>
        <p>
          Our progressing entity has expanded and radiated its production in
          various domains. To name some, our top-notch products include tea,
          pain balm, instant pain relief oil, instant pain relief spray, tooth
          powder, salt, ayurvedic medicine, timber, terracotta tiles, safety
          matches, and automobiles. We are proud to be called one of the best
          pain balm manufacturers in India. The Group is known for its
          high-quality pain balm and pain relief oil made in the Kajah Balm &
          Kajah Oil Factory located in Vallanad of Tuticorin District. These
          products are marketed throughout India and their market strength is
          steadily ameliorating. Among the edibles made under the brand name
          ‘Rajah and Kajah’ are vermicelli, atta, cool drinks, glucose drinks,
          peanut candy, payasam mix, and health mix. We are large-scale
          producers and distributors of packaging products, specifically from
          corrugated materials which are noted for their durability, dimensional
          accuracy, and biodegradable makeup. A catalog of our corrugated
          products will include paper sheets, paper rolls, printed boxes,
          laminated boxes, die-cut boxes, white duplex boxes, and heavy-duty
          boxes. Into the bargain, we provide customized boxes according to your
          needs.
        </p>
      </div>

      <div>
        <img
          src="../../../assets/images/oil-kajah - Copy.jpg"
          alt=""
          id="img"
          width="400px"
          height="300"
        />
      </div>
    </div>
  </div>

  <div class="section-container" data-aos="fade-up" data-aos-duration="1500">
    <h2>Our Health and Tourism Services</h2>

    <div class="flex-container">
      <div>
        <p>
          Considering the health tourism potential in Kerala, we own the
          picturesque Rajah Islands by the backwaters of Chettuva. Besides an
          Ayurvedic Hospital on the beachfront near Guruvayur, Kerala named
          Rajah Beach, we also have an Ayurvedic Treatment Center in Koottanad,
          Palakkad District of Kerala, with their international headquarters
          situated in Bangkok (Thailand), and Kuala Lumpur (Malaysia). These
          scenic locations have the potency to offer peace to the soul and
          refreshment to the mind. The infrastructure of Rajah Beach and Rajah
          Island is designed in such a way to help our tourists escape the
          monotony of life and rejuvenate awhile in the arms of nature. With all
          the state-of-the-art facilities of a modern Ayurvedic Center, our
          Ayurvedic Treatment Facility becomes an irresistible attraction for
          foreign tourists. An Ayurvedic Physiotherapy Centre in Tirunelveli is
          also a part of our health services, and the inhabitants of the
          villages close by are benefiting from this. Also, we manufacture our
          own ayurvedic products, most of which are utilized exclusively in
          Rajah Ayurvedic Centers. Some of these are exported outside.
        </p>
      </div>

      <div>
        <img
          src="../../../assets/images/Rajah Eco Beach.jpg"
          width="400px"
          height="300"
          alt=""
          id="img"
        />
      </div>
    </div>
  </div>

  <div class="section-container" data-aos="fade-up" data-aos-duration="1500">
    <h2>Our Industries</h2>

    <div class="flex-xontainer">
      <div>
        <p>
          The Rajah Group initiated a coir-pith factory on the national highway
          between Madurai and Trichy near Kottampatti. This was established
          aiming at the fullest utilization of the byproducts of coconut. We
          manufacture coco-pith blocks, which are in rising demand, coco-peat
          grow bags, which are excellent soil conditioners, and coco-chips.
          Considerable quantities of these are consigned to countries like
          Spain, Germany, Netherlands, Australia, and Dubai. Proximal to the
          coco-pith factory, our group has also established an activated carbon
          plant that produces Granular Activated Carbon(GAC) for water
          treatment. These products are freighted to the United States of
          America, Belgium, and the Netherlands.
        </p>
      </div>
    </div>
  </div>
</section>

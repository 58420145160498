<!-- IMAGE SLIDER -->

<div
  id="myCarousel"
  class="carousel slide carousel-fade"
  data-bs-ride="carousel"
  data-bs-pause="false"
>
  <!-- CAROUSEL INDICATORS -->
  <div class="carousel-indicators">
    <div
      *ngFor="let image of images; index as i; first as isFirst"
      [class.active]="isFirst"
    >
      <button
        type="button"
        data-bs-target="#myCarousel"
        [attr.data-bs-slide-to]="i"
        aria-current="true"
      ></button>
    </div>
  </div>

  <!-- CAROUSEL IMAGES -->
  <div class="carousel-inner">
    <div
      class="carousel-item active"
      data-bs-interval="2000"
      *ngFor="let image of images; first as isFirst"
      [class.active]="isFirst"
    >
      <img id="carouselImage" src="{{ image }}" class="d-block w-100" alt="" />
      <!-- height="707vh"  -->
      <div class="carousel-caption d-none d-md-block">
        <h3>It Differentiates Your Brand From Others</h3>
        <p>Kajah Industrial is The leading Industrial In The Country.</p>
        <button class="btn" (click)="btnService()">Watch Our Services</button>
      </div>
    </div>
  </div>

  <!-- NEXT AND PREV BUTTON -->
  <button
    class="carousel-control-prev"
    type="button"
    data-bs-target="#myCarousel"
    data-bs-slide="prev"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button
    class="carousel-control-next"
    type="button"
    data-bs-target="#myCarousel"
    data-bs-slide="next"
  >
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

<!-- ABOUT -->
<section class="section">
  <div class="section-container">
    <div class="flex-container">
      <div class="flex-intro" data-aos="fade-right" data-aos-duration="2000">
        <h1>Welcome to Kajah Industries!</h1>
        <p>
          Our company has recently extended its fruitful branches into the
          packaging sector. We manufacture a wide range of packaging products
          made out of corrugated materials. We procure excellent paper sheets
          from reliable sources and bring to you the best definition of
          packaging.
        </p>
        <button class="button" (click)="onClick()">Read more</button>
      </div>
      <div data-aos="fade-left" data-aos-duration="2000">
        <img
          src="../../../assets/images/about1.jpg"
          alt=""
          width="400px"
          height="300px"
          id="img"
        />
      </div>
    </div>
  </div>
</section>

<!-- BANNER MODEL -->
<div class="banner-grid">
  <div
    class="banner"
    data-aos="flip-right"
    data-aos-offset="200"
    data-aos-duration="2000"
  >
    <p>Paper Boxes</p>
    <img
      src="../../../assets/images/img1.png"
      alt=""
      width="100"
      height="100"
    />
  </div>

  <div
    class="banner"
    data-aos="flip-right"
    data-aos-offset="200"
    data-aos-duration="2000"
  >
    <p>Paper Sheets</p>
    <img
      src="../../../assets/images/img2.png"
      alt=""
      width="100"
      height="100"
    />
  </div>

  <div
    class="banner"
    data-aos="flip-right"
    data-aos-offset="200"
    data-aos-duration="2000"
  >
    <p>Printed Boxes</p>
    <img
      src="../../../assets/images/img3.png"
      alt=""
      width="100"
      height="100"
    />
  </div>

  <div
    class="banner"
    data-aos="flip-right"
    data-aos-offset="200"
    data-aos-duration="2000"
  >
    <p>Paper Rolls</p>
    <img
      src="../../../assets/images/img4.png"
      alt=""
      width="100"
      height="100"
    />
  </div>

  <div
    class="banner"
    data-aos="flip-right"
    data-aos-offset="200"
    data-aos-duration="2000"
  >
    <p>Heavy Boxes</p>
    <img
      src="../../../assets/images/img5.png"
      alt=""
      width="100"
      height="100"
    />
  </div>
</div>

<!-- SERVICES -->

<div>
  <div class="service-header">
    <h1>Our Services</h1>
  </div>

  <div class="service-container">
    <div class="service-grid">
      <img class="grid_img" src="../../../assets/images/product1.jpg" alt="" />
      <div class="service-content">
        <p>Corrugated Paper Box</p>
        <p>
          This three-layered packaging product is stiff and durable. The
          dimensions of the boxes can be altered to best suit your demands.
        </p>
      </div>
    </div>

    <div class="service-grid">
      <img class="grid_img" src="../../../assets/images/roll.jpg" alt="" />
      <div class="service-content">
        <p>Corrugated Paper Roll</p>
        <p>
          These are made of paper sheets of high calibre that are fine packing
          units to wrap brittle items like glassware, plasticware, etc...
        </p>
      </div>
    </div>

    <div class="service-grid">
      <img class="grid_img" src="../../../assets/images/product3.jpg" alt="" />
      <div class="service-content">
        <p>Printed Corrugated Box</p>
        <p>
          We ensure the distinction of your brand by supplying printed
          corrugated boxes. We take great care in expressing ​high-quality
          images.
        </p>
      </div>
    </div>

    <div class="service-grid">
      <img class="grid_img" src="../../../assets/images/product4.jpg" alt="" />
      <div class="service-content">
        <p>Heavy-Duty Corrugated Box</p>
        <p>
          These boxes can be used to ship heavyweight objects like machinery,
          home appliances, furniture, and more.
        </p>
      </div>
    </div>

    <div class="service-grid">
      <img class="grid_img" src="../../../assets/images/product5.jpg" alt="" />
      <div class="service-content">
        <p>Laminated Corrugated Box</p>
        <p>
          These boxes that are available in different thickness options are
          known for their colour variety and attractive nature.
        </p>
      </div>
    </div>

    <div class="service-grid">
      <img class="grid_img" src="../../../assets/images/product8.png" alt="" />
      <div class="service-content">
        <p>Custom Box</p>
        <p>
          This option enables our customers to make the best use of our services
          by availing of corrugated packaging products.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="wrapper">
  <button class="btn-service" (click)="btnService()">Read more</button>
</div>
